import NavBar from './NavBar';
import React from 'react';

function App() {
  return (
    <div >  
      <NavBar/>
    </div>
  );
}

export default App;
